import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Tabs, Tab } from '@mui/material';

function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Display = (props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.handleChange(newValue);
  };

  return (
    <Box
      className="tab-wrapper"
      sx={{
        display: 'flex',
        mt: '28px',
        fontSize: '14px',
        fontWeight: 500,
        gap: '32px',
        borderBottom: 1,
        borderColor: 'divider',
        width: '80%',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="loan offer status"
      >
        <Tab label="Offers" {...allyProps(0)} />
        <Tab label="Application In Progress" {...allyProps(1)} />
        <Tab label="Application Complete" {...allyProps(2)} />
      </Tabs>
    </Box>
  );
};

export default Display;
